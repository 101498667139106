<template>
    <div class="mine-complaint">
        
		<div class="tabs bg-fff flex"> 
			<div class="item" v-for="(item,index) in tabsList" 
				:key="index"
			>
				<span :class="index==tabIdx?'active':''" 
					@click="handleTab(index)"
				>
					{{item.name}}
				</span>
			</div>
		</div>
		<div class="content"> 
			<div v-if="tabIdx==0"> 
				<div v-if="allList.length">
					<complaintItem v-for="(item,index) in allList" 
						:key="index"
						:detail="item"
					/>
				</div>
				<div v-if="isEmpty">
					<Blank  :title="emptyText" tip="ts"></Blank>
				</div>
			</div>
			<div v-if="tabIdx==1"> 
				<div v-if="pendList.length">
					<complaintItem v-for="(item,index) in pendList" 
					:key="index"
					:detail="item"
				/>
				</div>
				
				<div v-if="isEmpty">
					<Blank  :title="emptyText" tip="ts"></Blank>
				</div>
			</div>
			<div v-if="tabIdx==2"> 
				<div v-if="completeList.length">
					<complaintItem v-for="(item,index) in completeList" 
						:key="index"
						:detail="item"
					/>
				</div>
				<div v-if="isEmpty">
					<Blank  :title="emptyText" tip="ts"></Blank>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
	import Blank from "@/components/blank.vue";
	import complaintItem from "@/components/mine/complaintItem.vue";
	import {getComplaintList} from "@/api/complaint";
    export default{
        components: { 
            Blank,
            complaintItem
        },
        data() {
          return {
            title:"我的投诉",
            backVisible: true,
            empty:false,
            value1: '',
            emptyText:"暂无投诉记录哦!",
            tabIdx:0,
            tabsList:[
                {
                    name:"全部",
                },
                {
                    name:"处理中",
                },
                {
                    name:"已处理",
                },
            ],
            statusCode:{
                1:{
                    statusText:"处理中",
                    statusColor:"#FE0000"
                },
                2:{
                    statusText:"已处理",
                    statusColor:"#999999"
                },
            },
            allList:[],
            pendList:[],
            completeList:[],
            isEmpty:false,
            complaintArr:[
                "allList","pendList","completeList",
            ],
            }
        },
        methods: {
            submit() {
                console.log('点击了提交')
            },
			handleTab(idx){
				this.tabIdx = idx;
				this.requestComplaint({status:idx});
                this.isEmpty = false;
			},
			handleDataList(data){
				let status = this.statusCode;
				for (var i = 0; i < data.length; i++) {
					let s = data[i].status;
					data[i].statusText = status[s].statusText;
					data[i].statusColor = status[s].statusColor;
				}
				return data;
			},
			requestComplaint(param){
        
				getComplaintList(param).then(res=>{
					let {status} = res;
					if(status){
						let data = res.data.pageList.records;
                        if(data.length == 0){
                            this.isEmpty = true;
                        }
						let listName = this.complaintArr[this.tabIdx];
						this[listName] = data;
            
					}
				})
			},
        },
		created(){
			// let data=[
			// 	{	
			// 		id:"11",
			// 		status: 2,
			// 	},
			// 	{
			// 		id:"12",
			// 		status: 1,
			// 	},
			// ];	
			let param = {
				status:this.tabIdx,
			};
			this.requestComplaint(param);

		}
    };
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.mine-complaint {
    padding-top: 50px;
}
.mine-complaint .tabs{
	height: 45px;
	padding: 0 20px;
}
.mine-complaint .tabs>div{
	height: 45px;
	flex: 1;
	text-align: center;
	line-height: 45px;
	color: #494949;
	font-size: 15px;
	
}
.mine-complaint .tabs>div span{
	display: inline-block;
	height: 45px;
	width: 70%;
}
.mine-complaint .tabs span.active{
	/* color: #0371FC;
	border-bottom: 2px solid #0371FC; */
	
	color: #4D62B8;
	border-bottom: 2px solid #4D62B8;
}
.mine-complaint .content{
	padding: 20px 20px 0 20px;
}
.mine-complaint  .c-item{
	padding: 13.5px 15px 24px 15px;
	margin-bottom: 20px;
}
.mine-complaint  .c-item .header{
	height: 13.5px;
	justify-content: space-between;
	align-items: center;
}
.mine-complaint  .c-item .header span{
	font-size: 13.5px;
}
.mine-complaint  .c-item .header .number{
	color: #999999;
}
.mine-complaint  .c-item .header .status{
	color: #0371FC;
}
.mine-complaint  .c-item .name{
	height: 26px;
	margin-top: 34px;
	align-items: center;
} 
.mine-complaint  .c-item .name img{
	width: 30px;
	height: 26px;
}
.mine-complaint  .c-item .name span{
	font-size: 18px;
	font-family: Noto Sans S Chinese;
	font-weight: 500;
	color: #222222;
	margin-left: 10px;
}
.mine-complaint  .c-item .time{
	height: 12.5px;
	margin-top: 16px;
	align-items: center;
}
.mine-complaint  .c-item .time img{
	width: 12px;
	height: 12px;
}
.mine-complaint  .c-item .time span{
	margin-left: 7px;
	font-size: 13px;
	font-family: Noto Sans S Chinese;
	font-weight: 400;
	color: #999999;
}

</style>
