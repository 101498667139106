<template>
	<div class="c-item bg-fff" 
		@click="goToDetail(detail.id)"
	>
		<div class="header flex">
			<span class="number">合同编号 {{detail.contractCode}}</span>
			<span class="status" :style="{color:detail.statusColor}">
				{{detail.statusText}}
			</span>
		</div>
		<div class="name flex">
			<img src="@/assets/yq/contract-03.png">
			<span>
				{{detail.contractName}}
			</span>
		</div>
		<div class="time flex">
			<img src="@/assets/yq/clock.png">
			<span>
				发起{{detail.sendTime}}
			</span>
		</div>
	</div>

</template>

<script>
	export default {
		props: {
			detail: {
				default: {},
			},
		},
		data() {
			return {

			};
		},
		methods: {
			goToDetail(id) {
				// wx.navigateTo({
				// 	url: `/pages/mine/complaintDetail/main?id=${id}`,
				// });
				this.$router.push({
					path: "/complaintDetail",
					query:{
						id
					}
				})
			},
		},
	};
</script>

<style>
.mine-complaint  .c-item{
	padding: 13.5px 15px 24px 15px;
	margin-bottom: 20px;
}
.mine-complaint  .c-item .header{
	height: 13.5px;
	justify-content: space-between;
	align-items: center;
}
.mine-complaint  .c-item .header span{
	font-size: 13.5px;
}
.mine-complaint  .c-item .header .number{
	color: #999999;
}
.mine-complaint  .c-item .header .status{
	font-size: 14px;
	font-family: Noto Sans S Chinese;
	font-weight: normal;
}
.mine-complaint  .c-item .name{
	height: 26px;
	margin-top: 34px;
	align-items: center;
} 
.mine-complaint  .c-item .name img{
	width: 30px;
	height: 26px;
}
.mine-complaint  .c-item .name span{
	font-size: 18px;
	font-family: Noto Sans S Chinese;
	font-weight: 500;
	color: #222222;
	margin-left: 10px;
}
.mine-complaint  .c-item .time{
	height: 12.5px;
	margin-top: 16px;
	align-items: center;
}
.mine-complaint  .c-item .time img{
	width: 12px;
	height: 12px;
}
.mine-complaint  .c-item .time span{
	margin-left: 7px;
	font-size: 13px;
	font-family: Noto Sans S Chinese;
	font-weight: 400;
	color: #999999;
}
</style>
